import { encodeBase64URLString } from '@thoughtspot/codec-util';
import { useCallback, useEffect, useRef, useState } from 'react';
import useFetch, { CachePolicies } from 'use-http';
import { useMutation } from '../../utils/error-handling/apollo-hooks-wrappers/custom-apollo-hooks';
import {
    AddUsersToOrgDocument,
    AddUsersToOrgInput,
    AddUsersToOrgMutation,
    AddUsersToOrgMutationVariables,
    ResetPasswordDocument,
    ResetPasswordMutation,
    ResetPasswordMutationVariables,
    UpdateDisplayNameDocument,
    UpdateDisplayNameMutation,
    UpdateDisplayNameMutationVariables,
    useGetCordClientAuthTokenQuery as _useGetCordClientAuthTokenQuery,
    useGetUserAccessibleOrgsLazyQuery as _useGetUserAccessibleOrgsLazyQuery,
    useGetUserAccessibleOrgsQuery as _useGetUserAccessibleOrgsQuery,
    useIsSessionActiveQuery as _useIsSessionActiveQuery,
    useSwitchOrgMutation as _useSwitchOrgMutation,
} from '../generated/graphql-types';

export const useIsSessionActiveQuery = () => {
    const {
        startPolling,
        stopPolling,
        data,
        loading,
        error,
    } = _useIsSessionActiveQuery({
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    return {
        startPolling,
        stopPolling,
        loading,
        error,
        data,
    };
};

const callosumIsActiveUrl = '/callosum/v1/session/isactive';
export const useIsSessionActiveFetchHook = () => {
    const [data, setData] = useState(null);
    const pollingRef = useRef(null);

    const fetchData = useCallback(async () => {
        const res = await fetch(callosumIsActiveUrl, {
            headers: {
                'X-Session-Check': 'true',
            },
        });

        if (!res?.ok) {
            setData({
                error: {
                    networkError: {
                        statusCode: res.status,
                    },
                },
            });
            return;
        }
        const resJson = await res.json();
        setData({
            data: {
                isSessionActive: {
                    status: res.status,
                    meta: {
                        ...resJson?.data,
                    },
                },
            },
        })
    }, []);

    const startPolling = (pollingInterval = 10000) => {
        if (!pollingRef.current) {
            pollingRef.current = setInterval(fetchData, pollingInterval);
        }
    };

    const stopPolling = () => {
        clearInterval(pollingRef.current);
        pollingRef.current = null;
    }

    useEffect(() => () => {
        // clear the polling on unmount.
        if (pollingRef.current) {
            stopPolling();
        }
    }, []);

    return {
        data: data?.data,
        error: data?.error,
        startPolling,
        stopPolling,
    };
};

export const useSwitchOrgMutation = (
    onComplete: (data: any) => void,
    onError: (error: any) => void,
    orgId: string,
) =>
    _useSwitchOrgMutation({
        variables: {
            orgId,
        },
        update: (cache, result) => {
            onComplete(result?.data.Session__switchOrg);
        },
        onError: (error: any) => {
            onError(error);
        },
    });

export const useGetUserAccessibleOrgsQuery = () => {
    const { data, error, loading, refetch } = _useGetUserAccessibleOrgsQuery();
    return {
        error,
        loading,
        data: data?.getUserAccessibleOrgs,
        refetch,
    };
};

export const useGetUserAccessibleOrgsLazyQuery = () => {
    const [
        getUserAccessibleOrgs,
        { data, loading, error },
    ] = _useGetUserAccessibleOrgsLazyQuery();

    return {
        getUserAccessibleOrgs,
        error,
        loading,
        data: data?.getUserAccessibleOrgs,
    };
};

export const useGetCordClientAuthToken = (isAnnotationEnabled = false) => {
    const { data, error, loading } = _useGetCordClientAuthTokenQuery({
        fetchPolicy: 'cache-and-network',
        skip: !isAnnotationEnabled,
        variables: {
            host: encodeBase64URLString(window.location.origin),
        },
    });
    return {
        error,
        loading,
        cordClientToken: data?.getCordClientAuthToken,
    };
};

export const useAddUsersToOrgMutation = () => {
    const [_addUsersToOrg, { data: result, error, loading }] = useMutation<
        AddUsersToOrgMutation,
        AddUsersToOrgMutationVariables
    >(AddUsersToOrgDocument);

    return {
        data: result?.Session__addUsersToOrg,
        error,
        loading,
        addUsersToOrg: (data: AddUsersToOrgInput) =>
            _addUsersToOrg({
                variables: {
                    data,
                },
            }),
    };
};

export const useUpdateDisplayName = () => {
    const [updateDisplayName, { data, error, loading }] = useMutation<
        UpdateDisplayNameMutation,
        UpdateDisplayNameMutationVariables
    >(UpdateDisplayNameDocument);

    return {
        data: data?.Session__updateDisplayName,
        error,
        loading,
        updateDisplayName: (displayName: string) =>
            updateDisplayName({
                variables: {
                    displayName,
                },
            }),
    };
};

export const useRequestPasswordReset = () => {
    const { post } = useFetch('/prism/preauth/forgotpassword', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'x-requested-by': 'ThoughtSpot',
        },
        cachePolicy: CachePolicies.NO_CACHE,
    });

    function doRequestPasswordReset(username: string): Promise<any> {
        const formData = new URLSearchParams();
        formData.append('username', username);
        return post(formData);
    }

    return { doRequestPasswordReset };
};

export const useActivateUser = () => {
    const { post } = useFetch('/prism/preauth/activate', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'x-requested-by': 'ThoughtSpot',
        },
        cachePolicy: CachePolicies.NO_CACHE,
    });

    function doActivateUser(
        userId: string,
        authToken: string,
        newPassword: string,
        email: string,
        companyName: string,
    ): Promise<any> {
        const formData = new URLSearchParams();
        formData.append('userId', userId);
        formData.append('authToken', authToken);
        formData.append('newPassword', newPassword);
        formData.append('email', email);
        formData.append('companyName', companyName);
        return post(formData).then(response => {
            if (response.extensions && response.extensions.upstreamResponse.status >= 400) {
                throw response;  // Throw an error if status is >= 400
            }
            return response;
        })
    }
    return { doActivateUser };
};

export const useResetPassword = () => {
    const [resetPassword, { data, error, loading }] = useMutation<
        ResetPasswordMutation,
        ResetPasswordMutationVariables
    >(ResetPasswordDocument);

    return {
        data: data?.Session__resetPassword,
        error,
        loading,
        resetPassword: (userId: string, token: string, password: string) =>
            resetPassword({
                variables: {
                    userId,
                    token,
                    password,
                },
            }),
    };
};
