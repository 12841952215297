import { generateUUID } from '@thoughtspot/js-util';
import {isNil, merge } from 'lodash';

type HeaderRuleCallback = (url: URL) => Record<string, any>;

export class HeadersManager {
    private headers: Record<string, string> = {};

    public headerRules: Map<string, HeaderRuleCallback> = new Map();

    setHeader(key: string, value: string) {
        const valueExists = !isNil(value);

        if (valueExists) {
            this.headers[key] = value;
        }
    }

    getHeaders(): Record<string, string> {
        return this.headers;
    }

    applyHeaderRules(url: URL, options: RequestInit) {
        const headers: Record<string, string> = {}; // Appended headers

        // Automatically check conditions and apply applicable rules
        this.headerRules.forEach((callback) => {
            merge(headers, callback(url) ?? {});
        });

        return {
            ...options,
            headers: {
                ...options.headers,
                ...headers,
            },
        };
    }

    addHeaderRule(headerRuleId: string, headerRuleCallback: HeaderRuleCallback) {
        this.headerRules.set(headerRuleId, headerRuleCallback);
    }

    removeHeaderRule(headerRuleId: string) {
        // Remove the rule with the specified headerRuleId
        this.headerRules.delete(headerRuleId);
    }

    resetHeaders() {
        this.headers = {};
    }

    addHeadersToFetch(
        resource: RequestInfo | URL,
        options: RequestInit = {},
    ): Request {
        const LocalRequest = window.Request;
        const urlLink =
            resource instanceof Request ? resource.url : resource.toString();
        const urlToFetch = new URL(urlLink, window.origin);

        // Apply rules to generate new options
        const newOptions = this.applyHeaderRules(urlToFetch, options);

        if (urlToFetch.origin === window.origin) {
            newOptions.headers = {
                ...newOptions.headers,
                ...this.headers,
            };
        }

        return new LocalRequest(resource, newOptions);
    }

    addHeadersToXHR(xhr: XMLHttpRequest, url: string) {
        const urlLink = url?.toString();
        const urlToFetch = new URL(urlLink, window.origin);
        if (urlToFetch.origin === window.origin) {
            Object.entries(this.headers).forEach(([key, value]) => {
                xhr.setRequestHeader(key, value);
            });
        }
    }
}

export const headersManager = new HeadersManager();
// Add rule for '/prism' URL
headersManager.addHeaderRule('PrismTraceId', (url: URL) => {
    const header: Record<string, string> = {};
    if (url.href.includes('/prism')) {
        header['X-Prism-Trace-Id'] = generateUUID();
    }
    return header;
});

export type HeadersManagerType = InstanceType<typeof HeadersManager>;
