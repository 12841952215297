import { create } from '@thoughtspot/logger';
import { dispatchNonMemoEvent } from '/@utils/event-bridge/event-bridge';
import { EventType } from '../embed-base.util';
import { HeadersManagerType } from './header-manager';

const logger = create('browser-fetch-xhr-custom-override');

let commonTokenPromise: Promise<unknown> | null = null;

export const TOKEN_EXPIRY_ERROR_STATUS_CODES = [401, 500, 502];

export const tokenApi = () => {
    if (!commonTokenPromise) {
        commonTokenPromise = dispatchNonMemoEvent(EventType.AuthExpire, {});
        commonTokenPromise
            .then(() => {
                commonTokenPromise = null;
                return null;
            })
            .catch(() => {
                commonTokenPromise = null;
            });
    }
    return commonTokenPromise;
};

export const fetchNewToken = async (headersManager: HeadersManagerType) => {
    try {
        const tokenResponse = (await tokenApi()) as {
            data: { authToken: string };
        };
        if (tokenResponse?.data?.authToken) {
            headersManager.setHeader(
                'Authorization',
                `Bearer ${tokenResponse.data.authToken}`,
            );
            return true;
        }
    } catch (err) {
        logger.error('Could not fetch new token ', err);
    }
    return false;
};
