import {
    DOCUMENT_TITLE_CONTEXT,
    PrivilegedRouteObject,
    useDocumentTitle,
    useEmbedContext,
    useGetErrorInfo,
} from '@thoughtspot/blink-context';
import { GLOBAL_ALERT_CONTAINER_ID } from '@thoughtspot/global-alert';
import { useTranslation } from '@thoughtspot/i18n';
import { Vertical } from '@thoughtspot/radiant-react/widgets/layout';
import { LoadingIndicator } from '@thoughtspot/radiant-react/widgets/loading-indicator';
import { setLast401Path } from '@thoughtspot/session-service';
import cx from 'classnames';
import _ from 'lodash';
import React, { Suspense, useEffect, useMemo } from 'react';
import {
    matchPath,
    Outlet,
    redirect,
    useLocation,
    useMatches,
} from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { useAppContext } from '/@contexts/global-app-context';
import { BlinkV2MetricsContextStates } from '/@services/system/blink-v2-metrics-context-states';
import {
    FLAGS,
    flags,
    getQueryParamsAndQueryString,
} from '/@services/system/config-service/flags-service';
import { getProductName } from '/@services/system/config-service/session-service';
import { useAuthenticatedState } from '/@services/system/login-service';
import { useUpdateUserPreferences } from '/@services/user-admin/user-admin-service';
import { EventType, isEmbeddedApp } from '/@utils/embed-base.util';
import { dispatchNonMemoEvent } from '/@utils/event-bridge/event-bridge';
import { CustomOverrideLinkComponent } from '../components/customoverridelink/custom-override-link.container';
import NavContainer from '../components/nav/nav.container';
import { NonEmbedAccess } from '../components/non-embed';
import { InitSessionCheckContainer } from '../components/session-status-check/init-session-check.container';
import { SetupProgressContainer } from '../components/setup-hub/setup-progress/setup-progress.container';
import { ClientStateContextProvider } from '../contexts/global-client-state';
import { ErrorBoundary } from '../utils/error-boundary/error-boundary';
import styles from './authenticated-app-view.module.scss';
import { CORE_ROUTES, getCurrentPathByLocationHash } from './core-routes';
import { PrivilegeList } from './privilege-list';
import { ApplicationList, ROUTES } from './routes';
import { isAnswerCreateAutoSetupPage } from './routes-util';
import { showSetupV2ProgressBar } from './setup-progress-bar/setup-v2-progress-bar-util';

const GlobalBanner = React.lazy(() =>
    import('../components/global-banners/global-banners.container'),
);

const MduBannerNotification = React.lazy(() =>
    import('../components/mdu-banner/index.container'),
);

const DebugBannerNotification = React.lazy(() =>
    import('../components/debug-banner/debug-banner.container'),
);

const ErrorBannerNotification = React.lazy(() =>
    import('../components/error-banner/error-banner.container'),
);

const EulaBanner = React.lazy(() =>
    import('../components/eula-banner/eula-banner.container'),
);

const SchedhuleMaintainanceComponent = React.lazy(() =>
    import(
        '../components/schedhule-maintainance/schedhule-maintainance.container'
    ),
);

export const AppView: React.FC = () => {
    const location = useLocation();
    const matches = useMatches();
    const { t } = useTranslation();
    const { sessionService, navService } = useAppContext();
    const { embedParams, isPoweredFooterHidden } = useEmbedContext();
    const { updateUserPreferences } = useUpdateUserPreferences({
        errorPolicy: 'ignore',
        onError: () => {
            // onError is needed to suffice useMutationWrapper check
            // TODO: Implement SCAL-230506 for better approach to ignore error
        },
    });
    const { errors } = useGetErrorInfo();
    const authenticationState = useAuthenticatedState();

    const pathname = location?.pathname || '';
    const queryString = location?.search || '';
    const isDebugMode = sessionService.isInDebugMode();
    const isEmbedded = isEmbeddedApp();
    const companyTitle = getProductName();
    const disableRedirectToV1 = !flags.getValue(FLAGS.disableRedirectToV1.name);

    useDocumentTitle({
        companyTitle,
    } as DOCUMENT_TITLE_CONTEXT);

    const { shouldRedirectToV1, isTopNavHidden } = useMemo(() => {
        const currentAppRoute = matches.find(
            match => (match.handle as any)?.handle,
        );

        return {
            shouldRedirectToV1:
                process.env.NODE_ENV !== 'development' &&
                ApplicationList[currentAppRoute?.id]?.shouldRedirectToV1 &&
                !sessionService.v1Disabled(),
            isTopNavHidden:
                (navService &&
                    !navService.canShowNavBar(
                        isEmbedded,
                        sessionService.isPrimaryNavHidden(),
                        sessionService.isNonEmbedFullAppAccessBlocked(),
                    )) ||
                matches.some(match => ROUTES[match.id]?.disableTopNav === true),
        };
    }, [matches]);

    useUpdateEffect(() => {
        dispatchNonMemoEvent(EventType.RouteChange, {
            currentPath: location?.pathname,
        });
    }, [location?.pathname]);

    // Redirect unsupported applications to V1
    useEffect(() => {
        if (shouldRedirectToV1 && disableRedirectToV1) {
            const { queryString } = getQueryParamsAndQueryString();
            const path =
                queryString.length > 0
                    ? `/v1/${queryString}#${pathname}`
                    : `/v1/#${pathname}`;
            const clientQueryParams = navService.readClientQueryParamsFromPath();
            let newPath = `${path}?`;
            clientQueryParams.forEach((value, name) => {
                newPath = `${newPath}${name}=${value}&`;
            });
            window.location.replace(newPath.slice(0, -1));
        }
    }, [shouldRedirectToV1]);

    useEffect(() => {
        // When auth expires, API's fail with 401. Dispatch AuthExpire event on such error.
        // TODO: Move the dispatch event logic after isActive check is added
        if (errors.find(err => err?.errorObj?.statusCode === 401)) {
            dispatchNonMemoEvent(EventType.AuthExpire, null);
        }
    }, [errors]);

    useEffect(() => {
        updateUserPreferences(
            sessionService.getUserGuid(),
            JSON.stringify(sessionService.getExposedUserPreferences()),
        );
    }, []);
    const getAllPossiblePaths = (
        routePattern: string,
        currentPossiblePaths: string[],
        index: any,
    ) => {
        return routePattern.endsWith('?')
            ? [
                  ...currentPossiblePaths,
                  ...currentPossiblePaths.map(
                      path => path + routePattern.substring(0, index - 1),
                  ),
              ]
            : [...currentPossiblePaths.map(path => path + routePattern)];
    };

    // custom function for optional params as matchPath() does not supports these from react-router-v6
    const generateAllPossiblePaths = (
        routePattern: string,
        currentPossiblePaths: string[],
    ): string[] => {
        if (!routePattern || routePattern.length === 0) return [];
        const currParamIndex = routePattern.indexOf('/', 1);
        const currParam = routePattern.substring(0, currParamIndex);
        if (currParamIndex > 0) {
            return [
                ...generateAllPossiblePaths(
                    routePattern.substring(currParamIndex),
                    getAllPossiblePaths(
                        currParam,
                        currentPossiblePaths,
                        currParamIndex,
                    ),
                ),
            ];
        }
        return getAllPossiblePaths(
            routePattern,
            currentPossiblePaths,
            routePattern.length,
        );
    };

    useEffect(() => {
        if (
            !Object.values(ROUTES).some(routePattern => {
                const allRoutePaths = generateAllPossiblePaths(
                    routePattern.path,
                    [''],
                );
                if (
                    allRoutePaths.some(routePath => {
                        return (
                            routePath !== '*' &&
                            matchPath(
                                {
                                    path: routePath || '',
                                    exact: true,
                                },
                                location.pathname,
                            ) !== null
                        );
                    })
                )
                    return true;
                return false;
            })
        ) {
            const { queryString } = getQueryParamsAndQueryString();
            window.history.replaceState(null, '', `${queryString}#/`);
        }
    }, [location?.pathname]);

    const pageContentClassName = useMemo(() => {
        if (
            pathname.indexOf('sql-view') !== -1 ||
            pathname.indexOf('analytics') !== -1 ||
            pathname.indexOf('dbt-integration') !== -1 ||
            pathname.indexOf('spotapp/publish') !== -1 ||
            pathname.indexOf('destination/auth') !== -1 ||
            (pathname.indexOf('spotapp') !== -1 &&
                pathname.indexOf('auth') !== -1) ||
            pathname.indexOf('custom-calendar') !== -1 ||
            pathname.indexOf('embrace/connection') !== -1 ||
            pathname.indexOf('remapping') !== -1 ||
            (pathname.indexOf('embrace') !== -1 &&
                pathname.indexOf('edit') !== -1) ||
            pathname.indexOf('importcsv') !== -1 ||
            pathname.indexOf('data-model') !== -1 ||
            pathname.indexOf('table-selection') !== -1 ||
            pathname.indexOf('requestaccess') !== -1 ||
            (pathname.indexOf('liveboard-schedules') !== -1 &&
                pathname.indexOf('data') !== -1) ||
            isAnswerCreateAutoSetupPage(pathname, queryString)
        ) {
            return styles.pageContentFullHeight;
        }

        return styles.pageContent;
    }, [pathname]);

    if (!isEmbedded) {
        if (
            sessionService.isNonEmbedFullAppAccessBlocked() ||
            (sessionService.isCurrentUserPublic() &&
                !matchPath(ROUTES.EverywhereStandalonePage.path, pathname))
        ) {
            return <NonEmbedAccess />;
        }
    }

    if (shouldRedirectToV1 && disableRedirectToV1) {
        return (
            <LoadingIndicator.Global className={styles.loadingIndicator}>
                <></>
            </LoadingIndicator.Global>
        );
    }

    // Since sessionInfo is nullified during logout,
    // we are adding the following statement to prevent the navigation and
    // other components from rendering. This ensures that the interface reflects
    // the user's logged-out state accurately.
    if (
        authenticationState.authenticationChangeInProgress ||
        authenticationState.isAuthenticated === false
    ) {
        return (
            <LoadingIndicator.Global className={styles.loadingIndicator}>
                <></>
            </LoadingIndicator.Global>
        );
    }

    // Note: Banner alerts will appear below the nav bar
    // inside the element with id={GLOBAL_ALERT_CONTAINER_ID}
    return (
        <Vertical
            className={cx(styles.blink, {
                [styles.blinkInEmbedWithFooter]:
                    !!isEmbedded && !isPoweredFooterHidden,
                [styles.blinkInEmbedFullHeight]:
                    !!isEmbedded && !!embedParams?.isFullHeightPinboard,
            })}
            grow
        >
            {embedParams.primaryNavHidden ?? isTopNavHidden ? null : (
                <NavContainer />
            )}
            <div
                id={GLOBAL_ALERT_CONTAINER_ID}
                data-testid={GLOBAL_ALERT_CONTAINER_ID}
            />
            <Suspense
                fallback={
                    <LoadingIndicator.Global
                        className={styles.loadingIndicator}
                    >
                        <></>
                    </LoadingIndicator.Global>
                }
            >
                {!!sessionService.getMduBannerConfig() && (
                    <MduBannerNotification />
                )}
                {!!isDebugMode && <DebugBannerNotification />}
            </Suspense>
            <Suspense
                fallback={
                    <LoadingIndicator.Global
                        className={styles.loadingIndicator}
                    >
                        <></>
                    </LoadingIndicator.Global>
                }
            >
                <EulaBanner />
            </Suspense>
            <Suspense
                fallback={
                    <LoadingIndicator.Global
                        className={styles.loadingIndicator}
                    >
                        <></>
                    </LoadingIndicator.Global>
                }
            >
                <SchedhuleMaintainanceComponent />
            </Suspense>
            <Suspense
                fallback={
                    <LoadingIndicator.Global
                        className={styles.loadingIndicator}
                    >
                        <></>
                    </LoadingIndicator.Global>
                }
            >
                {(sessionService.isTrialVersionEnabled() ||
                    sessionService.isSlackBotEnabled()) && <GlobalBanner />}
            </Suspense>
            <Suspense
                fallback={
                    <LoadingIndicator.Global
                        className={styles.loadingIndicator}
                    >
                        <></>
                    </LoadingIndicator.Global>
                }
            >
                <ErrorBannerNotification />
            </Suspense>
            <Vertical className={pageContentClassName} grow>
                <ErrorBoundary hasRouter>
                    <Suspense
                        fallback={
                            <LoadingIndicator.Global
                                className={styles.loadingIndicator}
                            >
                                <></>
                            </LoadingIndicator.Global>
                        }
                    >
                        <ClientStateContextProvider>
                            <Suspense
                                fallback={
                                    <LoadingIndicator.Global
                                        className={styles.loadingIndicator}
                                    >
                                        <></>
                                    </LoadingIndicator.Global>
                                }
                            >
                                <Outlet />
                                {sessionService.isLoggedIn() && (
                                    <InitSessionCheckContainer />
                                )}
                            </Suspense>
                        </ClientStateContextProvider>
                    </Suspense>
                </ErrorBoundary>
            </Vertical>
            {showSetupV2ProgressBar(pathname, sessionService, navService) && (
                <Vertical className={styles.setupProgress}>
                    <SetupProgressContainer />
                </Vertical>
            )}
        </Vertical>
    );
};

export const AuthenticatedRoutes: PrivilegedRouteObject = {
    element: (
        <>
            <AppView />
            <CustomOverrideLinkComponent />
        </>
    ),
    children: {
        ...ApplicationList,
    },
    fallback: () => {
        setLast401Path(window.location.hash.substring(1));
        throw redirect(CORE_ROUTES.Login.path);
    },
    shouldRevalidate: () => true,
    privileges: [PrivilegeList.MustBeAuthenticated],
    pageContext: BlinkV2MetricsContextStates.DEFAULT_PAGE,
};
