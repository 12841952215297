import type { RequestPropsType } from './request-interceptor';
import { requestInterceptor } from './request-interceptor';

const setAuthToken = (value: string) => {
    const valueExists = value !== null && value !== undefined;
    if (valueExists) {
        requestInterceptor.setHeader('Authorization', `Bearer ${value}`);
    }
};

const setCSRFToken = (value: string) => {
    requestInterceptor.setHeader('X-CSRF-Token', value);
};


const overrideBrowserFetch = (args: RequestPropsType) => {
    requestInterceptor.overrideFetch(args);
};

const overrideBrowserXhr = (args: RequestPropsType) => {
    requestInterceptor.overrideXHR(args);
};

const overrideBrowserRequest = (args: RequestPropsType) => {
    requestInterceptor.overrideFetch(args);
    requestInterceptor.overrideXHR(args);
};

const overrideRequestOptions = (args: any) => {
    return requestInterceptor.overrideOptions(args);
};

export {
    setAuthToken,
    setCSRFToken,
    overrideBrowserRequest,
    overrideBrowserFetch,
    overrideBrowserXhr,
    overrideRequestOptions,
    RequestPropsType,
};
