// TODO : Import these from SDK (SCAL-232192)
// These are the contracts for the Ui passthrough methods available for Ui Passthrough interface fro SDK
export enum UiPassthroughEvent {
    addVizToPinboard = 'addVizToPinboard',
    saveAnswer = 'saveAnswer',
    getPinboardTabInfo = 'getPinboardTabInfo',
    getDiscoverabilityStatus = 'getDiscoverabilityStatus',
    getAvailableUiPassthroughs = 'getAvailableUiPassthroughs',
    getAnswerPageConfig = 'getAnswerPageConfig',
    getPinboardPageConfig = 'getPinboardPageConfig',
    UiPassthroughEventNotFound = 'UiPassthroughEventNotFound',
}

export type UiPassthroughContractBase = {
    [UiPassthroughEvent.addVizToPinboard]: {
        request: {
            vizId?: string;
            newVizName: string;
            newVizDescription?: string;
            pinboardId?: string;
            tabId?: string;
            newPinboardName?: string;
            newTabName?: string;
            pinFromStore?: boolean;
        };
        response: {
            pinboardId: string;
            tabId: string;
            vizId: string;
            errors?: any;
        };
    };
    [UiPassthroughEvent.saveAnswer]: {
        request: {
            name: string;
            description: string;
            vizId: string;
            isDiscoverable?: boolean;
        };
        response: {
            saveResponse: {
                data?: {
                    Answer__save: {
                        answer: {
                            id: string;
                        };
                    };
                };
                errors?: any;
            };
            shareResponse?: {
                Share?: boolean;
                errors?: any;
            };
        };
    };
    [UiPassthroughEvent.getPinboardTabInfo]: {
        request: any;
        response: any;
    };
    [UiPassthroughEvent.getDiscoverabilityStatus]: {
        request: any;
        response: {
            saveWithDiscoverability: boolean;
            shouldShowDiscoverability: boolean;
        };
    };
    [UiPassthroughEvent.getAvailableUiPassthroughs]: {
        request: any;
        response: {
            keys: string[];
        };
    };
    [UiPassthroughEvent.getAnswerPageConfig]: {
        request: {
            vizId?: string;
        };
        response: any;
    };
    [UiPassthroughEvent.getPinboardPageConfig]: {
        request: any;
        response: any;
    };
    [UiPassthroughEvent.UiPassthroughEventNotFound]: {
        request: any;
        response: any;
    };
};
