/**
 * @file Ui Passthrough Interface
 *
 * @fileoverview This file contains the interface that uses event-bridge platform to register hooks and other api functionalities
 * the functions / methods registered via this framework are supposed to be an proxy / passthrough for an UI interaction for Embed use cases.
 */

import { create, LogLevel } from '@thoughtspot/logger';
import {
    UiPassthroughContractBase,
    UiPassthroughEvent,
} from './ui-passthrough-contracts';

export type UiPassthroughContract = UiPassthroughContractBase;

export type UiPassthroughFunction<
    FunctionName extends keyof UiPassthroughContract
> = (
    args: UiPassthroughContract[FunctionName]['request'],
) => Promise<UiPassthroughContract[FunctionName]['response']>;

// Adding the refId to each function in UiPassthroughContract
type WithRefId<T> = T & { tsEmbedRefId?: string };

const UiPassthroughMap = new Map<
    string,
    Set<WithRefId<UiPassthroughFunction<keyof UiPassthroughContract>>>
>();

export const clearUiPassthroughMap = () => UiPassthroughMap.clear();

/**
 * Subscribes a callback function to a specified UI passthrough event, enabling
 * interactions with the EventBridge platform in embedded use cases.
 *
 * @param functionName - The name of the UI passthrough function to subscribe to.
 *                       This must be a valid key within `UiPassthroughContract`.
 * @param callback - The callback function to execute when the specified event occurs.
 *                   This function should match the signature expected by the UI passthrough
 *                   function indicated by `functionName`.
 * @param refId - (Optional) A unique reference ID to distinguish this subscription while `debugging`,
 *                particularly useful when multiple callbacks are associated with the same event.
 *                For example, when rendering multiple visualizations on a screen, the `refId`
 *                can represent a unique visualization ID to differentiate callbacks.
 * @example - subscribeUiPassthroughFunction(
 *              UiPassthroughEvent.addVizToPinboard,
 *              (vizId) => vizPinApi(vizId),
 *              container.vizId
 *            );`
 * @returns A function that, when called, unsubscribes the callback from the specified event.
 */
export const subscribeUiPassthroughFunction = <
    FunctionName extends keyof UiPassthroughContract
>(
    functionName: FunctionName,
    callback: UiPassthroughFunction<FunctionName>,
    refId?: string,
) => {
    //
    const callbackWithRefId: WithRefId<UiPassthroughFunction<
        FunctionName
    >> = callback;
    callbackWithRefId.tsEmbedRefId = refId;

    const eventName = functionName;
    if (!UiPassthroughMap.has(eventName)) {
        UiPassthroughMap.set(eventName, new Set());
    }
    UiPassthroughMap.get(eventName).add(callbackWithRefId);

    return () => UiPassthroughMap.get(eventName).delete(callbackWithRefId);
};

/**
 * Handles a UI passthrough event by executing all registered callbacks for the specified event type.
 * Each callback is invoked with the provided parameters, and results are returned with associated reference IDs.
 *
 * @param data - The event data containing:
 *               - `type`: The type of UI passthrough event.
 *               - `parameters`: Optional parameters to pass to each callback.
 * @returns A promise that resolves to an array of results, each with a `refId` and either a `value` or `error`,
 *          depending on the outcome of each callback.
 */
export const handleUiPassthroughEvent = async (data: any): Promise<any> => {
    const UiPassthroughType = data?.type;
    const params = data?.parameters || {};
    const callbacks = UiPassthroughMap.get(UiPassthroughType);
    const callbacksArray = Array.from(callbacks || []);
    const promises = callbacksArray.map(callback => callback(params));

    const res = await Promise.allSettled(promises);

    const resWithRefId = res.map((result, index) => {
        const refId = callbacksArray[index].tsEmbedRefId;
        if (result.status === 'rejected') {
            return {
                refId,
                // post message is not able to handle Error object
                error: { message: result?.reason?.message },
            };
        }

        return {
            refId: callbacksArray[index].tsEmbedRefId,
            value: result.value,
        };
    });

    return resWithRefId;
};

export const getRegisteredUiPassthroughs = () => {
    const keys = Array.from(UiPassthroughMap.keys());
    return Promise.resolve({
        keys,
    });
};
// Utility events registered globally
export const registerGlobalUiPassthroughFunctions = () => {
    const unSubscribeFunctions = [
        subscribeUiPassthroughFunction(
            UiPassthroughEvent.getAvailableUiPassthroughs,
            getRegisteredUiPassthroughs,
        ),
    ];

    return unSubscribeFunctions;
};

export const initUiPassthroughEvent = (
    eventName: string,
    eventBridgeSubscribe: any,
) => {
    eventBridgeSubscribe(eventName, handleUiPassthroughEvent);
    registerGlobalUiPassthroughFunctions();
};
