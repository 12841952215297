import { inIFrame } from '@thoughtspot/browser-util';
import {
    getClientLocale,
    getPreferredLocaleID,
    initializeDateFormats,
    initializeNumberUtil,
    initializeStrings,
} from '@thoughtspot/i18n';
import { create } from '@thoughtspot/logger';
import {
    getQuarterStartMonth,
    getSessionTimeZone,
    getUserGuid,
    isCustomCalendarEnabled,
    isDarkCluster,
    isOptimizelyEnabled,
    onThirdPartyServicesInitiated,
} from '@thoughtspot/session-service';
import {
    getMixpanelAccessToken,
    isIntercomEnabled,
    isPendoEnabled,
    isPublicUserEnabled,
} from '@thoughtspot/system-config-service';
import { isWebView } from '/@utils/embed-base.util';

const CONNECTIVITY_REQUEST_TIMEOUT_MS = 500;
const Logger = create('lifecycle-hooks-service');

export const isEmbedded = (path = '') => {
    return inIFrame() || path.indexOf('/embed/') > -1 || isWebView();
};

/**
 * Third party Services
 */
const initPendoServiceAndInsertScript = async () => {
    if (isPendoEnabled()) {
        try {
            const PendoService = await import('/@services/pendo/pendo-service');
            PendoService.initPendoScript({
                getUserPreferredLocale: getPreferredLocaleID,
            });
        } catch (err) {
            Logger.warn(err);
        }
    }
};

const initExperimentationService = async () => {
    if (isOptimizelyEnabled()) {
        try {
            const ExperimentationService = await import(
                '/@services/experimentation/experimentation-service'
            );
            ExperimentationService.initializeExperimentationService();
        } catch (err) {
            Logger.warn(err);
        }
    }
};

const initializeMetrics = async () => {
    try {
        const MetricsService = await import(
            '/@services/metrics-service/metrics-service'
        );
        if (MetricsService.isMixpanelAllowed()) {
            await MetricsService.initMetricsService();
            await MetricsService.initEventCollection();
        } else {
            MetricsService.disableMetricsService();
        }
    } catch (err) {
        Logger.warn(err);
    }
};

const initializeIntercom = async () => {
    if (isIntercomEnabled()) {
        try {
            const IntercomService = await import(
                '/@services/intercom/intercom-service'
            );
            IntercomService.initIntercomScript();
        } catch (err) {
            Logger.warn(err);
        }
    }
};

const initializeObservabiltiy = async () => {
    try {
        const ObservabilityService = await import(
            '/@services/system/observability-service/observability-service'
        );
        await ObservabilityService.initializeObservability();
    } catch (err) {
        Logger.error(err);
    }
};

export const initializeThirdPartyServices = async () => {
    if (isDarkCluster()) {
        Logger.info('Dark cluster detected. Skipping 3rd party services');
        return;
    }
    try {
        await Promise.all([
            initPendoServiceAndInsertScript(),
            initializeMetrics(),
            initializeIntercom(),
            initializeObservabiltiy(),
        ]);
        await initExperimentationService();
        onThirdPartyServicesInitiated.publish();
    } catch (err) {
        Logger.warn('Failed to initialize 3rd party services', err);
    }
};

/**
 * Internal Services
 */

export const initializeCustomCalendars = async () => {
    if (isCustomCalendarEnabled()) {
        try {
            const CustomCalendarService = await import(
                '@thoughtspot/custom-calendar-service'
            );
            CustomCalendarService.initAvailableCustomCalendars();
        } catch (err) {
            Logger.warn(err);
        }
    }
};

export const initializeTooltip = async () => {
    try {
        await import('../../../tooltip-wrapper.module.scss');
        const TooltipWidget = await import(
            '@thoughtspot/radiant-react/widgets/tooltip'
        );
        TooltipWidget.initializeTooltip();
    } catch (err) {
        Logger.warn(err);
    }
};

export const notifySDKOnAuth = async () => {
    if (isEmbedded()) {
        try {
            const { notifyAuthInit } = await import(
                '/@services/everywhere/everywhere-service.util'
            );
            notifyAuthInit(
                getUserGuid(),
                getMixpanelAccessToken(),
                isPublicUserEnabled(),
            );
        } catch (err) {
            Logger.warn(err);
        }
    }
};
export const setEmbedInitData = async (data: any) => {
    if (isEmbedded()) {
        try {
            const { setEmbedInitData } = await import('/@utils/embed.util');
            await setEmbedInitData(data);
        } catch (err) {
            Logger.warn(err);
        }
    }
};

export function initializeLocaleFormats(
    dateFormatLocale: string,
    numberFormatLocale: string,
    currencyFormat: string,
): Promise<[void, void, void]> {
    const userLocale = getClientLocale();
    return Promise.all([
        initializeNumberUtil(numberFormatLocale || userLocale, {
            currencyFormat,
        }),
        initializeStrings(userLocale),
        initializeDateFormats(
            getQuarterStartMonth(),
            getSessionTimeZone(),
            dateFormatLocale || userLocale,
        ),
    ]);
}

export const testonly__exports = {
    initializeIntercom,
    initializeMetrics,
    initExperimentationService,
    initPendoServiceAndInsertScript,
    initializeObservabiltiy,
};
