import { headersManager, HeadersManagerType } from './header-manager';
import { fetchNewToken, TOKEN_EXPIRY_ERROR_STATUS_CODES } from './utils';

export type RequestPropsType = { isEmbedApp: boolean; hasAuthToken?: boolean };

class RequestInterceptor {
    constructor(private headersManager: HeadersManagerType) {
        this.headersManager = headersManager;
    }

    overrideFetch(props: RequestPropsType) {
        const { isEmbedApp, hasAuthToken } = props || {};
        const nativeFetch = window.fetch;
        const retryRequestWithToken = RequestInterceptor.createRetryFunction(
            isEmbedApp,
            this.headersManager,
        );

        window.fetch = async (input: RequestInfo | URL, init?: RequestInit) => {
            const modifiedRequest = this.headersManager.addHeadersToFetch(
                input,
                init,
            );
            const initialResponse = await nativeFetch(modifiedRequest);
            if (!isEmbedApp || !hasAuthToken) return initialResponse;

            return retryRequestWithToken(
                initialResponse,
                input,
                init,
                nativeFetch,
            );
        };
    }

    overrideXHR(...props: any[]) {
        const nativeOpen = XMLHttpRequest.prototype.open;
        const headersManager = this.headersManager;
        (window.XMLHttpRequest as any).prototype.open = function xhrOverideFunc(
            ...args: any[]
        ) {
            this.addEventListener('readystatechange', () => {
                if (this.readyState === XMLHttpRequest.OPENED) {
                    const url = args?.[1];
                    headersManager.addHeadersToXHR(this, url);
                }
            });
            nativeOpen.apply(this, args);
        };
    }

    overrideOptions({ options }: any) {
        const headers = this.headersManager.getHeaders();
        // eslint-disable-next-line no-param-reassign
        options.headers = {
            ...headers,
        };
        return options;
    }

    setHeader(key: string, value: string) {
        this.headersManager.setHeader(key, value);
    }

    resetHeaders() {
        this.headersManager.resetHeaders();
    }

    static createRetryFunction = (
        isEmbedApp: boolean,
        headersManager: HeadersManagerType,
    ) => {
        return async (
            initialResponse: Response,
            input: RequestInfo | URL,
            init: RequestInit | undefined,
            nativeFetch: typeof fetch,
        ) => {
            const isAuthTokenExpired = TOKEN_EXPIRY_ERROR_STATUS_CODES.includes(
                initialResponse.status,
            );
            if (!isAuthTokenExpired) return initialResponse;

            const newAuthToken = await fetchNewToken(headersManager);
            if (!newAuthToken) return initialResponse;

            // Retry the request with the new token
            const retriedRequest = headersManager.addHeadersToFetch(
                input,
                init,
            );
            return nativeFetch(retriedRequest);
        };
    };
}

export const requestInterceptor = new RequestInterceptor(headersManager);

export type RequestInterceptorType = InstanceType<typeof RequestInterceptor>;
