import { initFeatureFlagsContext } from '@thoughtspot/blink-context';
import { envFlags, FLAGS } from '@thoughtspot/env-flags';
import {
    getLDContext,
    initialize as initFeatureService,
} from '@thoughtspot/feature-service';
import { create } from '@thoughtspot/logger';
import { handleLoginResponse } from '@thoughtspot/session-service';
import {
    getOidcLoginUriFromIssuers,
    isOidcEnabled,
    isOktaEnabled,
    setSystemConfig,
} from '@thoughtspot/system-config-service';
import React from 'react';
import { BehaviorSubject } from 'rxjs';
import useFetch, { CachePolicies } from 'use-http';
import {
    initSessionService,
    isPartitionedCookieEnabled,
} from '/@services/system/config-service/session-service';
import { postAuthentication } from '/@services/system/lifecycle-hooks-service/lifecycle-hooks-service';
import { dispatchNonMemoEvent } from '/@utils/event-bridge/event-bridge';
import {
    overrideRequestOptions,
    setCSRFToken,
} from '/@utils/http-request-polyfill';
import { EventType } from '../../utils/embed.util';
import { useMutation } from '../../utils/error-handling/apollo-hooks-wrappers/custom-apollo-hooks';
import {
    DoLogoutDocument,
    DoLogoutMutation,
    DoLogoutMutationVariables,
} from '../generated/graphql-types';
import { initCustomStylingService } from './config-service/custom-styling-service';

const Logger = create('login-service');
const AUTHENTICATION_TIMEOUT = 30000;

export type AuthenticationState = {
    isAuthenticated: boolean;
    authenticationChangeInProgress: boolean;
};

const isAuthenticatedSubject: BehaviorSubject<AuthenticationState> = new BehaviorSubject<
    AuthenticationState
>({
    isAuthenticated: undefined,
    authenticationChangeInProgress: true,
});

const updateServices = async ({
    info,
    config,
    style,
    launchdarklyFlags,
}: any) => {
    if (config) {
        setSystemConfig(config);
    }
    if (style) {
        initCustomStylingService(style);
    }
    if (info) {
        await postAuthentication(info);
    }
    if (launchdarklyFlags) {
        initFeatureFlagsContext({ launchdarklyFlags });
        initFeatureService(getLDContext(), launchdarklyFlags);
    }
};

export const getIsUserAuthenticated = (): Promise<boolean> => {
    if (isAuthenticatedSubject.hasError || isAuthenticatedSubject.isStopped) {
        const latestAuthState = isAuthenticatedSubject.getValue();
        if (
            latestAuthState.isAuthenticated !== true ||
            latestAuthState.authenticationChangeInProgress === true
        ) {
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }
    return new Promise<boolean>(resolve => {
        // eslint-disable-next-line promise/param-names
        const failSafe = new Promise<boolean>(resolveFailSafe => {
            setTimeout(() => resolveFailSafe(false), AUTHENTICATION_TIMEOUT);
        });
        // eslint-disable-next-line promise/param-names
        const authPromise = new Promise<boolean>(resolveAuth => {
            const waitForAuthenticaiton = () => {
                isAuthenticatedSubject.subscribe(
                    (authState: AuthenticationState) => {
                        if (authState.authenticationChangeInProgress) {
                            return null;
                        }
                        return resolveAuth(authState.isAuthenticated);
                    },
                );
            };
            return waitForAuthenticaiton();
        });
        return Promise.race([authPromise, failSafe]).then(resolve);
    });
};

export const useAuthenticatedState = () => {
    const [authState, setAuthState] = React.useState<AuthenticationState>(
        isAuthenticatedSubject.getValue(),
    );
    React.useEffect(() => {
        const subscription = isAuthenticatedSubject.subscribe(
            (newAuthState: AuthenticationState) => {
                setAuthState(newAuthState);
            },
        );
        return () => {
            subscription.unsubscribe();
        };
    }, []);
    return authState;
};

export const useAuthenticate = async () => {
    if (!isAuthenticatedSubject.getValue().authenticationChangeInProgress) {
        isAuthenticatedSubject.next({
            isAuthenticated: undefined,
            authenticationChangeInProgress: true,
        });
    }
    let fetchResponse;

    if ((window as any)?.preauthInfo) {
        fetchResponse = (window as any)?.preauthInfo;

        const data = await fetchResponse.clone().json();

        if (data) {
            updateServices(data);
        }

        if (data.status === 200) {
            isAuthenticatedSubject.next({
                isAuthenticated: true,
                authenticationChangeInProgress: false,
            });
        } else {
            dispatchNonMemoEvent(EventType.AuthFailure, null);
            isAuthenticatedSubject.next({
                isAuthenticated: false,
                authenticationChangeInProgress: false,
            });
        }
    } else {
        const fetchResponse = useFetch(
            '/prism/preauth/info',
            {
                method: 'GET',
                headers: {
                    'x-requested-by': 'ThoughtSpot',
                },
                interceptors: {
                    request: overrideRequestOptions,
                },
            },
            [],
        );
        const { data, response } = fetchResponse;
        React.useEffect(() => {
            if (data) {
                updateServices(data);
            }
            if (response.ok) {
                const csrfToken = response.headers?.get('X-CSRF-Token');

                if (csrfToken) {
                    setCSRFToken(csrfToken);
                }

                isAuthenticatedSubject.next({
                    isAuthenticated: true,
                    authenticationChangeInProgress: false,
                });
            } else if (response.ok === false) {
                setCSRFToken('');
                dispatchNonMemoEvent(EventType.AuthFailure, null);
                isAuthenticatedSubject.next({
                    isAuthenticated: false,
                    authenticationChangeInProgress: false,
                });
            }
        }, [data, response.ok]);
    }

    return fetchResponse;
};

/**
 * Demo login for a public user. Doesn't require a username or password.
 */
export const useDemoLogin = () => {
    const { data, post, error, loading, response } = useFetch(
        '/prism/preauth/demo/login',
        {
            method: 'POST',
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
                'x-requested-by': 'ThoughtSpot',
            },
            cachePolicy: CachePolicies.NO_CACHE,
        },
    );
    React.useEffect(() => {
        if (response.ok) {
            updateServices(data);
            isAuthenticatedSubject.next({
                isAuthenticated: true,
                authenticationChangeInProgress: false,
            });
        } else if (response.ok === false) {
            dispatchNonMemoEvent(EventType.AuthFailure, null);
            isAuthenticatedSubject.next({
                isAuthenticated: false,
                authenticationChangeInProgress: false,
            });
        }
    }, [data, response]);
    return {
        error,
        loading,
        login: () => {
            isAuthenticatedSubject.next({
                isAuthenticated: undefined,
                authenticationChangeInProgress: true,
            });
            (window as any).preauthInfo = post();
            return (window as any).preauthInfo;
        },
        response,
    };
};

export const useLogin = () => {
    const { data, post, error, loading, response } = useFetch(
        '/prism/preauth/login',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ThoughtSpot',
            },
            cachePolicy: CachePolicies.NO_CACHE,
        },
    );
    function login(username: string, password: string, rememberMe: boolean) {
        isAuthenticatedSubject.next({
            isAuthenticated: undefined,
            authenticationChangeInProgress: true,
        });
        (window as any).preauthInfo = post('', {
            username,
            password,
            rememberMe,
        });
        return (window as any).preauthInfo;
    }
    React.useEffect(() => {
        if (response.ok) {
            const csrfToken = response.headers?.get('X-CSRF-Token');

            if (csrfToken) {
                setCSRFToken(csrfToken);
            }
            updateServices(data);
            isAuthenticatedSubject.next({
                isAuthenticated: true,
                authenticationChangeInProgress: false,
            });
        } else if (response.ok === false) {
            setCSRFToken('');
            dispatchNonMemoEvent(EventType.AuthFailure, null);
            isAuthenticatedSubject.next({
                isAuthenticated: false,
                authenticationChangeInProgress: false,
            });
        }
    }, [data, response]);

    return {
        error,
        loading,
        login,
        response,
    };
};

const fetchPreauthInfo = () => {
    const overrideOrgId = envFlags.getValue(FLAGS.overrideOrgId.name);
    const allowOrgOverride = overrideOrgId != null && overrideOrgId !== '-1';
    const headers = allowOrgOverride
        ? {
              'x-requested-by': 'ThoughtSpot',
              'X-Thoughtspot-OrgsOverride': overrideOrgId,
          }
        : {
              'x-requested-by': 'ThoughtSpot',
          };
    return fetch('/prism/preauth/info', {
        method: 'GET',
        headers,
    });
};

export const useUpdateSessionInfo = async (onlyUpdateSessionInfo = false) => {
    try {
        if (!isAuthenticatedSubject.getValue().authenticationChangeInProgress) {
            isAuthenticatedSubject.next({
                isAuthenticated: undefined,
                authenticationChangeInProgress: true,
            });
        }

        let data;
        if ((window as any)?.preauthInfo) {
            data = await (window as any)?.preauthInfo;
        } else {
            (window as any).preauthInfo = fetchPreauthInfo();
            data = await (window as any)?.preauthInfo;
        }

        const csrfToken = await data.clone()?.headers?.get('X-CSRF-Token');
        setCSRFToken(csrfToken);

        const authInfo = await data.clone().json();
        if (onlyUpdateSessionInfo) {
            initSessionService(authInfo?.info);
            return;
        }
        await updateServices(authInfo);
        if (data.status === 200) {
            isAuthenticatedSubject.next({
                isAuthenticated: true,
                authenticationChangeInProgress: false,
            });
        } else {
            throw new Error(data.status.toString());
        }
    } catch (e) {
        setCSRFToken('');
        dispatchNonMemoEvent(EventType.AuthFailure, null);
        isAuthenticatedSubject.next({
            isAuthenticated: false,
            authenticationChangeInProgress: false,
        });
        Logger.warn('Error fetching session data', e);
    }
};

export const useDoLogout = () => {
    const [_doLogout, { data, error, loading }] = useMutation<
        DoLogoutMutation,
        DoLogoutMutationVariables
    >(DoLogoutDocument);

    // Helper function to handle the logout mutation and update the authentication state
    const handleLogout = () => {
        return _doLogout({}).then(() => {
            setCSRFToken('');
            isAuthenticatedSubject.next({
                isAuthenticated: false,
                authenticationChangeInProgress: false,
            });
            return null;
        });
    };

    // Helper function to perform OKTA-specific parallel actions
    const handleOktaLogout = (issuerLink: string) => {
        return Promise.all([
            fetch(`${issuerLink}/api/v1/sessions/me`, {
                method: 'DELETE',
                credentials: 'include',
            }).catch(() => {
                // Handle fetch error, if needed (optional)
            }),
            _doLogout({}),
        ]).then(() => {
            setCSRFToken('');
            isAuthenticatedSubject.next({
                isAuthenticated: false,
                authenticationChangeInProgress: false,
            });
            return null;
        });
    };

    return {
        data: data?.Session__doLogout,
        error,
        loading,
        doLogout: () => {
            isAuthenticatedSubject.next({
                isAuthenticated: undefined,
                authenticationChangeInProgress: true,
            });

            const issuerLink = getOidcLoginUriFromIssuers();

            // OKTA-enabled environment with partitioned cookies
            if (isOidcEnabled() && isOktaEnabled() && issuerLink) {
                const { origin } = new URL(issuerLink);
                if (isPartitionedCookieEnabled()) {
                    return handleOktaLogout(origin);
                }
            }
            // Default flow: perform logout mutation
            return handleLogout();
        },
    };
};

export const doShortLivedLogin = () => {
    const { post } = useFetch('callosum/v1/session/shortlivedlogin', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'x-requested-by': 'ThoughtSpot',
        },
        cachePolicy: CachePolicies.NO_CACHE,
    });
    function shortLivedLogin(userId: string, authToken: string): Promise<any> {
        const formData = new URLSearchParams();
        formData.append('id', userId);
        formData.append('shortlivedauthenticationtoken', authToken);
        return post(formData).then(handleLoginResponse);
    }

    return { shortLivedLogin };
};

export const useAuthentication = () => {
    const { doLogout } = useDoLogout();
    const { shortLivedLogin } = doShortLivedLogin();

    const authenticate = async (userGuid: string, authToken: string) => {
        try {
            await doLogout();
        } catch (error) {
            // Do nothing if already logged out
        }

        try {
            await shortLivedLogin(userGuid, authToken);
            return { authenticated: true };
        } catch (error) {
            return { authenticated: false, error };
        }
    };

    return { authenticate };
};

export const refetchPreauthInfo = async () => {
    (window as any).preauthInfo = fetchPreauthInfo();
    const data = await (window as any)?.preauthInfo;
    if (data) {
        const authInfo = await data.clone().json();
        updateServices(authInfo);
    }
};
