import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useRouteChanged = () => {
    const location = useLocation();
    const prevLocation = useRef(location);
    const hasChanged = useRef(false);
    const [state, setState] = useState(false);

    useEffect(() => {
        if (
            location.pathname !== prevLocation.current.pathname ||
            location.hash !== prevLocation.current.hash
        ) {
            setState(true);
            hasChanged.current = true;
            prevLocation.current = location; // Update previous location
        } else {
            if (state) {
                setState(false);
            }
            hasChanged.current = false;
        }
    }, [location]);

    return state;
};
