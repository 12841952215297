import { OTLPConfig, SeverityNumber } from '@thoughtspot/observability';

const SERVICE_NAME = 'thoughtspot-frontend';

export const OTLP_CONFIG: OTLPConfig = {
    traces: {
        url: '/prism/preauth/telemetry/traces',
        flushConfig: {
            flushIntervalMs: 10000,
            maxQueueSize: 1000,
            flushOnFull: true,
        },
    },
    metrics: {
        url: '/prism/preauth/telemetry/metrics',
        flushConfig: {
            flushIntervalMs: 15000,
            maxQueueSize: 1000,
            flushOnFull: true,
        },
    },
    logs: {
        url: '/prism/preauth/telemetry/logs',
        flushConfig: {
            flushIntervalMs: 5000,
            maxQueueSize: 200,
            flushOnFull: true,
        },
        severityMap: {
            [SeverityNumber.UNSPECIFIED]: 'otlp',
            [SeverityNumber.ERROR]: ['console', 'otlp'],
            [SeverityNumber.WARN]: ['console', 'otlp'],
        },
    },
    serviceName: SERVICE_NAME,
};
